<template>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column order-2 order-md-1">
              <div class="mt-1 mt-md-5 card card-plain white-bg">
                <div class="card-header pb-0 text-center">
                  <h3 class="font-weight-bolder text-success text-info pt-5">
                    Реєстрація
                  </h3>
                  <p class="mb-0">Введіть пошту та пароль для реєстрації</p>
                </div>
                <div class="card-body pb-3">
                  <Form
                    role="form"
                    class="text-start"
                    :validation-schema="schema"
                    @submit="handleRegister"
                  >
                    <div>
                      <label>Логін</label>
                      <soft-field
                        id="name"
                        v-model="user.name"
                        type="text"
                        placeholder="Логін"
                        name="name"
                      />
                      <label>Email</label>
                      <soft-field
                        id="email"
                        v-model="user.email"
                        type="email"
                        placeholder="Email"
                        name="email"
                      />
                      <label>Пароль</label>
                      <soft-field
                        id="password"
                        v-model="user.password"
                        type="password"
                        placeholder="Пароль"
                        name="password"
                      />
                      <label>Повторіть пароль</label>
                      <soft-field
                        id="password_confirmation"
                        v-model="user.password_confirmation"
                        type="password"
                        placeholder="Повторіть пароль"
                        name="password_confirmation"
                      />
                      <soft-check-model
                        id="agreed"
                        v-model="agreed"
                        name="agreed"
                        class="font-weight-light terms"
                      >
                        Я згоден з
                        <a href="#" class="text-dark font-weight-bolder"
                          >Правилами та Умовами</a
                        >
                      </soft-check-model>
                      <div>
                        <span
                          v-if="showError"
                          role="alert"
                          class="error-feedback text-xs text-danger"
                          >Будь ласка, прийміть Правила та умови!</span
                        >
                        <sp v-else></sp>
                      </div>
                      <div class="text-center">
                        <soft-button
                          color="info"
                          variant="gradient"
                          full-width
                          class="mt-2 mb-2"
                          :is-disabled="loading ? true : false"
                          ><span
                            v-if="loading"
                            class="spinner-border spinner-border-sm"
                          ></span>
                          <span v-else>Зареєструватися</span></soft-button
                        >
                      </div>
                    </div>
                  </Form>
                </div>

              </div>

              <div class="text-center">
                <p class="mx-auto p-1 mb-4 text-sm">
                  <span>Вже маєте акаунт?</span>
                  <router-link
                      :to="{ name: 'Login' }"
                      class="text-info text-white font-weight-bold"
                  >
                    Увійти
                  </router-link>
                </p>
              </div>
            </div>
            <div class="mx-auto mt-5 mt-md-8 col-xl-4 col-lg-5 col-md-6 d-flex flex-column order-1 order-md-2">
              <div>
                <div>
                  <div class="logo text-center">
                    <Logo />
                  </div>
                  <p class="text-center pt-4">СИСТЕМА ВІДДАЛЕНОГО КЕРУВАННЯ
                    КОТЛАМИ ТА ПАЛЬНИКАМИ тмBEETERM</p>
                </div>
                <div>
                  <p class="text-center pt-md-12 pt-0">ver. 1.0.0</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import SoftField from "@/components/SoftField.vue";
import SoftCheckModel from "@/components/SoftCheckModel.vue";
import SoftButton from "@/components/SoftButton.vue";
import showSwal from "/src/mixins/showSwal.js";
const body = document.getElementsByTagName("body")[0];
import { Form } from "vee-validate";
import * as yup from "yup";

import { mapMutations } from "vuex";
import Logo from "@/components/Icon/Logo.vue";
export default {
  name: "Register",
  components: {
    Logo,
    SoftField,
    SoftCheckModel,
    SoftButton,
    Form,
  },
  data() {
    const schema = yup.object().shape({
      name: yup.string().required("Введіть логін!"),
      email: yup
        .string()
          .required("Email обов'язковий!")
          .email("Має бути дійсний email!(name@gmail.com)"),
      password: yup
        .string()
        .required("Пароль обов'язковий!")
        .min(8, "Пароль має бути не менше 8 символів!"),
      password_confirmation: yup
        .string()
        .test("passwords-match", "Паролі мають збігатися", function (value) {
          return this.parent.password === value;
        }),
    });
    const user = {
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
    };
    return {
      agreed: false,
      loading: false,
      user,
      schema,
      showError: false,
    };
  },

  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    async handleRegister() {
      this.loading = true;

      if (!this.agreed) {
        this.showError = true;
      } else {
        this.showError = false;
        try {
          await this.$store.dispatch("auth/register", this.user);
          this.loading = false;
          showSwal.methods.showSwal({
            type: "success",
            message:
              "Успішно зареєстровано! Ви автоматично ввійшли в систему.",
            width: 600,
          });
          this.$router.push("/dashboards");
        } catch (error) {
          showSwal.methods.showSwal({
            type: "error",
            message: "Ця пошта вже зареєстрована.",
            width: 500,
          });
        }
      }
      this.loading = false;
    },
  },
};
</script>
<style>
.terms {
  font-size: 14px;
}
</style>
