<template>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column order-2 order-md-1">
              <div class="mt-1 mt-md-8 card card-plain white-bg">
                <div class="pb-0 card-header text-start">
                  <h3 class="font-weight-bolder text-success text-info text-center">
                    Увійти
                  </h3>
                </div>
                <div class="card-body pb-3">
                  <Form
                      role="form"
                      class="text-start"
                      :validation-schema="schema"
                      @submit="handleLogin"
                  >
                    <label for="email">Email</label>
                    <soft-field
                        id="email"
                        v-model="user.email"
                        type="email"
                        placeholder="Email"
                        name="email"
                    />

                    <label>Пароль</label>
                    <soft-field
                        id="password"
                        v-model="user.password"
                        type="password"
                        placeholder="Пароль"
                        name="password"
                    />

                    <soft-switch id="rememberMe" name="rememberMe" checked>
                      Запам'ятати мене
                    </soft-switch>

                    <div class="text-center">
                      <soft-button
                          class="my-4 mb-2"
                          variant="gradient"
                          color="info"
                          full-width
                          :is-disabled="loading ? true : false"
                      >
                        <span
                            v-if="loading"
                            class="spinner-border spinner-border-sm"
                        ></span>
                        <span v-else>Увійти</span>
                      </soft-button>
                    </div>
                  </Form>

                  <div class="text-center card-footer w-100 p-0">
                    <p class="mx-auto mb-0 text-sm">
                      або
                      <router-link :to="{ name: 'Register' }">
                        <soft-button
                            class="my-4 mb-2"
                            variant="info"
                            color="dark"
                            full-width
                        >
                          <span>Зареєструватися</span>
                        </soft-button>
                      </router-link>
                    </p>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <p class="mx-auto p-1 mb-4 text-sm">
                  <router-link
                      :to="{ name: 'SendEmail' }"
                      class="text-info text-white font-weight-bold"
                  >Скинути пароль
                  </router-link>
                </p>
              </div>
            </div>
            <div class="mx-auto mt-5 mt-md-8 col-xl-4 col-lg-5 col-md-6 d-flex order-1 order-md-2 flex-column">
              <div>
                <div>
                  <div class="logo text-center">
                    <Logo />
                  </div>
                  <p class="text-center pt-4">СИСТЕМА ВІДДАЛЕНОГО КЕРУВАННЯ
                    КОТЛАМИ ТА ПАЛЬНИКАМИ тмBEETERM</p>
                </div>
                <div>
                    <p class="text-center pt-md-12 pt-0">ver. 1.0.0</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import SoftField from "@/components/SoftField.vue";
import SoftSwitch from "@/components/SoftSwitch.vue";
import SoftButton from "@/components/SoftButton.vue";
import Logo from "@/components/Icon/Logo.vue";
import showSwal from "/src/mixins/showSwal.js";

const body = document.getElementsByTagName("body")[0];
import {mapMutations} from "vuex";
import {Form} from "vee-validate";
import * as yup from "yup";

export default {
  name: "Login",
  components: {
    SoftField,
    SoftSwitch,
    SoftButton,
    Form,
    Logo,
  },

  data() {
    const schema = yup.object().shape({
      email: yup
          .string()
          .required("Email обов'язковий!")
          .email("Має бути дійсний email!(name@gmail.com)"),
      password: yup.string().required("Необхідно ввести пароль!"),
    });
    const user = {
      email: "admin@jsonapi.com",
      password: "secret",
    };
    return {
      loading: false,
      user,
      schema,
    };
  },

  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    async handleLogin() {
      this.loading = true;
      try {
        await this.$store.dispatch("auth/login", this.user);
        this.$router.push("/dashboards");
      } catch (error) {
        showSwal.methods.showSwal({
          type: "error",
          message: "Невірні дані!",
        });
        this.loading = false;
      }
    },
  },
};
</script>
