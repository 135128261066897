<template>
  <div v-if="isVisible" class="modal" @click.self="close">
    <div class="modal-content">
      <span class="close" @click="close">&times;</span>
      <h2>{{ title }}</h2>

      <hr class="my-2 horizontal dark"/>
      <HalfGaugeChart
          :current-value="currentTemp"
          v-model:set-value="maxTemp"
          :max-value="maxValue"
      />

      <!-- Maximum temperature -->
      <div class="value_1">
        <h4 class="mb-0 font-weight-bolder">Максимальна температура:</h4>
        <NumberInput v-model="maxTemp" :setValue="setMaxTempValue" :minValue="minTemp" :maxValue="maxValue"/>
        <p class="d-none">Передане значення: {{ maxTemp }}</p>
      </div>

      <hr class="my-2 horizontal dark"/>

      <!-- Minimum temperature -->
      <div class="value_2">
        <h4 class="mb-0 font-weight-bolder">Мінімальна температура:</h4>
        <NumberInput v-model="minTemp" :setValue="setMinTempValue" :minValue="minValue" :maxValue="maxTemp"/>
        <p class="d-none">Передане значення: {{ minTemp }}</p>
      </div>

      <hr class="my-4 horizontal dark"/>

      <!-- Current temperature -->
      <div class="value_3 d-none">
        <h4 class="mb-0 font-weight-bolder">Поточна температура:</h4>
        <NumberInput v-model="currentTemp" :minValue="minTemp" :maxValue="maxTemp"/>
        <p class="d-none">Передане значення: {{ currentTemp }}</p>
      </div>

      <div class="btn-footer">
        <button class="mb-0 btn bg-gradient-danger" @click="close">Скасувати</button>
        <button class="mb-0 btn bg-gradient-info" @click="save">Зберегти</button>
      </div>
    </div>
  </div>
</template>

<script>
import NumberInput from "@/components/NumberInput.vue";
import HalfGaugeChart from "@/examples/Charts/HalfGaugeChart.vue";

export default {
  name: "ModalTemp",
  components: {
    HalfGaugeChart,
    NumberInput,
  },
  props: {
    title: String,
    currentValue: {
      type: Number,
      required: true,
      default: 0,
    },
    minValue: {
      type: Number,
      required: true,
      default: 80,
    },
    maxValue: {
      type: Number,
      required: true,
      default: 500,
    },
    setMinTempValue: {
      type: Number,
      required: true,
      default: 80,
    },
    setMaxTempValue: {
      type: Number,
      required: true,
      default: 500,
    },
  },
  emits: ["update:setMinTempValue", "update:setMaxTempValue", "update:currentValue"],
  data() {
    return {
      isVisible: false,
      currentTemp: this.currentValue,
      minTemp: Math.max(this.setMinTempValue, this.minValue), // Apply min boundary
      maxTemp: Math.min(this.setMaxTempValue, this.maxValue), // Apply max boundary
    };
  },
  watch: {
    minTemp(newVal) {
      this.$emit("update:setMinTempValue", newVal);
    },
    maxTemp(newVal) {
      this.$emit("update:setMaxTempValue", newVal);
    },
    currentTemp(newVal) {
      this.$emit("update:currentValue", newVal);
    },
  },
  methods: {
    open() {
      this.isVisible = true;
    },
    close() {
      this.isVisible = false;
    },
    save() {
      this.$emit("save", {
        setMinTemp: this.minTemp,
        setMaxTemp: this.maxTemp,
      });
      this.close();
    },
  },
};
</script>

<style scoped>
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 11111;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background: white;
  padding: 20px;
  max-width: 450px;
  border-radius: 5px;
}

.modal-content .btn-footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 21px;
}

.close {
  cursor: pointer;
  float: right;
  font-size: 2em;
}
</style>

