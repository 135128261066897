<template>
  <div class="py-4 container-fluid">
    <div v-if="isLoading & hasItems !== true" class="d-flex justify-content-center my-5">
      <img src="@/assets/img/loading.gif" width="100" height="100" alt="loading"/>
    </div>
    <div v-if="hasItems === false" class="d-grid justify-content-center my-5">
      <p>Пристроїв не знайдено</p>
      <div class="mt-4">
        <router-link to="/items/new" class="btn d-grid btn-add-device-big bg-gradient-success justify-items-center align-items-center p-3 btn-sm text-center">
          <i class="fa fa-solid fa-plus mb-2"></i> Додати пристрій
        </router-link>
      </div>
    </div>
    <div v-if="hasItems === true">
      <div class="mt-4 row d-flex justify-center row">
        <div class="col-md-4 col-12 text-center text-md-start order-3 order-md-1">
          <p>Режим роботи: <span class="text-gradient text-info text-uppercase">{{selectedDeviceData.mode_status}}</span></p>
        </div>
        <div class="col-md-4 col-12 text-center d-inline-block order-2 order-md-2">
          <div class="form-check form-switch ps-0 d-inline-flex">
            <input
                id="status"
                class="mt-1 form-check-input"
                type="checkbox"
                :checked="isEnabled"
                :disabled="isArchived"
                @change="toggleStatus"
            />
            <span>{{ statusLabel }}</span>
          </div>
        </div>
        <div class="col-md-4 col-12 text-center text-md-end pc-right align-items-center align-items-md-end order-1 order-md-3">
          <div class="d-xl-block" style="max-width: 300px">
            <select id="choices-item-edit" v-model="selectedDevice" class="form-control" name="choices-item" @change="onDeviceChange">
              <option v-for="item in itemsList" :key="item.id" :value="item.id">
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <hr class="my-2 horizontal dark"/>

      <div class="mt-6 grid grid-colum-4">
        <div class="col-md-12">
          <counter-card-kotel
              :current="selectedDeviceData.current_temp_kotla"
              :set-temp="selectedDeviceData.set_temp_kotla"
              :min-count="0"
              :max-count="80"
              :histeresis="selectedDeviceData.histeresis_kotla"
              :info-box="'Інтенсивність набору - ' + 0.5 + '<br>Гістерезіс - ' + 5"
              icon="TemCotel"
              suffix="°C"
              title="Температура котла"
              description="Змінити"
              popup="Інтенсивність набору -0.5"
              @save="updateDeviceData('kotla', $event)"
          />
        </div>
        <div class="col-md-12 ">
          <counter-card-boiler
              :current="selectedDeviceData.current_temp_boilera"
              :set-temp="selectedDeviceData.set_temp_boilera"
              :min-count="0"
              :max-count="80"
              :histeresis="selectedDeviceData.histeresis_boilera"
              :info-box="'Інтенсивність набору - ' + 0.5 + '<br>Гістерезіс - ' + 5"
              icon="TemBoiler"
              suffix="°C"
              title="Температура бойлера"
              description="Змінити"
              @save="updateDeviceData('boilera', $event)"
          />
        </div>

        <div class="col-md-12">
          <setting-counter-card
              :set-mode="selectedDeviceData.mode"
              icon="PumpMode"
              title="Режим насосів"
              description="Змінити"
              @save="updateDeviceData('mode', $event)"
          />
        </div>
        <div class="col-md-12 ">
          <temp-counter-card
              :current="selectedDeviceData.current_temp_gas"
              :set-min-temp="selectedDeviceData.min_temp_gas"
              :set-max-temp="selectedDeviceData.max_temp_gas"
              :min-count="80"
              :max-count="500"
              :info-box="'Діапазон дим.газів  ' + 0.5 + ' | ' + 5 + '°C'"
              icon="SmokeGases"
              suffix="°C"
              title="Димові гази"
              description="Змінити"
              @save="updateDeviceData('gas', $event)"
          />
        </div>
        <div class="col-md-12 ">
          <power-counter-card
              :current="selectedDeviceData.current_power_palnika"
              :set-temp="selectedDeviceData.set_power_palnika"
              :min-count="5"
              :max-count="1000"
              :modulation="selectedDeviceData.modulation"
              :info-box="`<div style='display: flex; justify-content: space-between; width: 100%;'>
                <span>Потужність пальника:</span> <span>0.5 | 5 °C</span>
            </div>
            <div style='display: flex; justify-content: space-between; width: 100%;'>
                <span>Модуляція</span> <span>увімк</span>
            </div>
            <div style='display: flex; justify-content: space-between; width: 100%;'>
                <span>Поточне спожив.п</span> <span>2.8 кг/год</span>
            </div>`"
              icon="PowerPalnik"
              suffix="kWt"
              title="Потужність пальника"
              description="Змінити"
              @save="updateDeviceData('power', $event)"
          />
        </div>

      </div>
      <div class="row">
        <SegmentedLoader :progress="selectedDeviceData.flame_brightness"/>
      </div>

      <hr class="my-4 horizontal dark"/>
      <div class="mt-4 row">
        <div class="col-lg-6 ms-auto">
          <reports-doughnut-chart/>
        </div>
        <div class="mt-4 col-lg-6 mt-lg-0">
          <div class="row">
            <div class="col-12">
              <thin-bar-chart
                  title="Споживання палива"
                  :chart="{
                    labels: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Нд'],
                    datasets: {
                      label: 'kg',
                      data: [150, 230, 380, 220, 420, 200, 70, 500]
                    }
                  }"
              />
            </div>
          </div>
        </div>
      </div>

      <hr class="my-5 horizontal dark"/>
    </div>
  </div>
</template>

<script>
import ReportsDoughnutChart from "@/examples/Charts/ReportsDoughnutChart.vue";
import ThinBarChart from "@/examples/Charts/ThinBarChart.vue";
import PowerCounterCard from "@/examples/Cards/PowerCounterCard.vue";

import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import roundSlider from "round-slider";
import showSwal from "@/mixins/showSwal.js";

import $ from "jquery";
import SettingCounterCard from "@/examples/Cards/SettingCounterCard.vue";
import SegmentedLoader from "@/components/SegmentedLoader.vue";
import TempCounterCard from "@/examples/Cards/TempCounterCard.vue";
import Choices from "choices.js";
import ItemService from "@/services/items.service.js";
import CounterCardKotel from "../../examples/Cards/CounterCardKotel.vue";
import CounterCardBoiler from "../../examples/Cards/CounterCardBoiler.vue";


export default {
  name: "Default",
  components: {
    CounterCardBoiler,
    CounterCardKotel,
    TempCounterCard,
    SegmentedLoader,
    SettingCounterCard,
    // eslint-disable-next-line vue/no-unused-components
    roundSlider,
    PowerCounterCard,
    ReportsDoughnutChart,
    ThinBarChart,
  },
  emits: ['save'],
  data() {
    return {
      selectedDeviceData: {},  // Дані обраного пристрою
      selectedDevice: null,    // ID обраного пристрою
      devices: [],             // Список всіх пристроїв
      showMenu: false,
      isLoading: true,
      hasItems: '',
      isPowerOn: false,
    };
  },
  computed: {
    isEnabled() {
      // Устройство включено, если статус "on"
      return this.selectedDeviceData.status === "on";
    },
    isArchived() {
      // Устройство заблокировано, если статус "archive"
      return this.selectedDeviceData.status === "archive";
    },
    statusLabel() {
      // Отображаемый текст в зависимости от статуса
      switch (this.selectedDeviceData.status) {
        case "on":
          return "Увімкнено";
        case "off":
          return "Вимкнено";
        case "archive":
          return "Заблоковано";
        default:
          return "Невідомий статус";
      }
    },

    currentUser() {
      const user = this.$store.getters["profile/profile"]?.id;
      return user;
    },
    itemsList() {
      return this.$store.getters["items/items"]?.data || [];
    },
  },
  watch: {
    itemsList(newItems) {
      this.hasItems = newItems.length > 0;
      if (this.hasItems) {
        // Фільтруємо пристрої, де is_on_homepage === 1
        const devicesOnHomepage = newItems.filter(device => device.is_on_homepage === true);
        if (devicesOnHomepage.length > 0 && !this.selectedDevice) {
          this.selectedDevice = devicesOnHomepage[0].id;
          this.selectedDeviceData = devicesOnHomepage[0];  // Зберігаємо дані першого пристрою
        }
      }
    }
  },
  mounted() {
    this.loadData(); // Загружаем данные при монтировании
    this.getChoices("choices-item-edit");
    setNavPills();
    setTooltip(this.$store.state.bootstrap);


    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
        "src",
        "https://cdn.jsdelivr.net/npm/round-slider@1.6.1/dist/roundslider.min.js"
    );
    document.head.appendChild(recaptchaScript);

    $("#slider").roundSlider({
      width: 22,
      radius: 100,
      value: 45,
      readOnly: true,
      circleShape: "half-top",
      sliderType: "min-range",
      lineCap: "round",
      min: 16,
      max: 38
    });
    // Rounded slider
    const setValue = function (value, active) {
      document.querySelectorAll("round-slider").forEach(function (el) {
        if (el.value === undefined) return;
        el.value = value;
      });
      const span = document.querySelector("#value");
      span.innerHTML = value;
      if (active) span.style.color = "red";
      else span.style.color = "black";
    };

    document.querySelectorAll("round-slider").forEach(function (el) {
      el.addEventListener("value-changed", function (ev) {
        if (ev.detail.value !== undefined) setValue(ev.detail.value, false);
        // eslint-disable-next-line no-undef
        else if (ev.detail.low !== undefined) setLow(ev.detail.low, false);
        // eslint-disable-next-line no-undef
        else if (ev.detail.high !== undefined) setHigh(ev.detail.high, false);
      });

      el.addEventListener("value-changing", function (ev) {
        if (ev.detail.value !== undefined) setValue(ev.detail.value, true);
        // eslint-disable-next-line no-undef
        else if (ev.detail.low !== undefined) setLow(ev.detail.low, true);
        // eslint-disable-next-line no-undef
        else if (ev.detail.high !== undefined) setHigh(ev.detail.high, true);
      });
    });
  },
  methods: {
    toggleStatus() {
      if (this.isArchived) {
        return; // Нічого не робимо, якщо пристрій заблоковано
      }
      const newStatus = this.selectedDeviceData.status === "on" ? "off" : "on";
      this.selectedDeviceData.status = newStatus; // Оновлюємо локальне значення

      // Зберігаємо зміни на сервері
      this.saveToServer("status", { status: newStatus });
    },

    // Вызывается при изменении устройства
    onDeviceChange() {
      const selectedDevice = this.itemsList.find(item => item.id === this.selectedDevice);
      if (selectedDevice) {
        this.selectedDeviceData = { ...selectedDevice }; // Обновляем данные устройства

        console.log("Selected Device ID:", this.selectedDevice);
      }

      console.log("Selected_2 Device ID:", this.selectedDevice);
    },
    // Обновление данных устройства
    updateDeviceData(key, updatedValues) {
      // Локальне оновлення даних пристрою
      if (key === "kotla") {
        this.selectedDeviceData.set_temp_kotla = updatedValues.setTemp;
        this.selectedDeviceData.histeresis_kotla = updatedValues.histeresis;
      } else if (key === "boilera") {
        this.selectedDeviceData.set_temp_boilera = updatedValues.setTemp;
        this.selectedDeviceData.histeresis_boilera = updatedValues.histeresis;
      } else if (key === "gas") {
        this.selectedDeviceData.min_temp_gas = updatedValues.setMinTemp;
        this.selectedDeviceData.max_temp_gas = updatedValues.setMaxTemp;
      } else if (key === "power") {
        this.selectedDeviceData.set_power_palnika = updatedValues.setTemp;
        this.selectedDeviceData.modulation = updatedValues.modulation;
      } else if (key === "mode") {
        this.selectedDeviceData.mode = updatedValues.mode;
      }

      // Надсилання змін на сервер
      this.saveToServer(key, updatedValues);
    },

    async saveToServer(key, updatedValues) {
      try {
        // Підготовка даних для редагування
        const itemData = {
          id: this.selectedDevice.toString(), // Ідентифікатор пристрою
        };
        if (key === "status" && updatedValues.status !== undefined) {
          itemData.status = updatedValues.status; // Додаємо статус
        }

        if (updatedValues.setTemp !== undefined) {
          if (key === "kotla") {
            itemData.set_temp_kotla = updatedValues.setTemp;
          }
          else if (key === "boilera") {
            itemData.set_temp_boilera = updatedValues.setTemp;
          }
          else if (key === "power") {
            itemData.set_power_palnika = updatedValues.setTemp;
          }
        }

        if (updatedValues.histeresis !== undefined) {
          if (key === "kotla") {
            itemData.histeresis_kotla = updatedValues.histeresis;
          } else if (key === "boilera") {
            itemData.histeresis_boilera = updatedValues.histeresis;
          }
        }

        if (updatedValues.setMinTemp !== undefined) {
          itemData.min_temp_gas = updatedValues.setMinTemp;
        }
        if (updatedValues.setMaxTemp !== undefined) {
          itemData.max_temp_gas = updatedValues.setMaxTemp;
        }
        if (updatedValues.modulation !== undefined) {
          itemData.modulation = updatedValues.modulation;
        }

        if (updatedValues.mode !== undefined) {
          itemData.mode = updatedValues.mode;
        }
        // Виклик ItemService для оновлення пристрою
        const response = await ItemService.editItem(itemData);

        console.log("Дані пристрою успішно оновлено:", response);

        // Опціонально: оновити локальні дані пристрою після успішного збереження
        await this.loadData();

        // Показати повідомлення про успіх
        showSwal.methods.showSwal({
          type: "success",
          message: "Дані пристрою успішно збережені!",
        });
      } catch (error) {
        console.error("Помилка при оновленні пристрою:", error);

        // Відобразити деталі помилки, якщо вони є
        if (error.response && error.response.data) {
          console.error("Деталі помилки:", error.response.data);
        }

        // Показати повідомлення про помилку
        showSwal.methods.showSwal({
          type: "error",
          message: "Не вдалося зберегти дані пристрою.",
        });
      }
    },

    getChoices(id) {
      if (document.getElementById(id)) {
        var element = document.getElementById(id);
        return new Choices(element, {
          searchEnabled: false,
        });
      }
    },
    async loadData() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("items/getItems", {
          filter: this.currentQuery ? {name: this.currentQuery} : {},
          page: {
            number: 1,
            size: 15,
          },
          sort: "created_at",
          user_id: this.currentUser,
        });
      } catch (error) {
        console.error("Ошибка загрузки items (332)", error);
      } finally {
        this.isLoading = false;
      }
    },

  },
};
</script>

<style>
.btn-add-device-big {
  justify-items: center;
}
.btn-add-device-big i.fa.fa-solid.fa-plus {
  padding: 10px;
  border-radius: 100%;
  border: 1px solid #fff;
  padding: 18px;
  width: 48px;
}
</style>