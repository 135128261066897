<template>
  <div class="chart-power m-auto">
    <canvas ref="progressChart"></canvas>
    <div class="temperature-display">{{ currentValue }}kWt</div>
  </div>
</template>

<script>
import {defineComponent, onMounted, ref, watch} from 'vue';
import {Chart, ArcElement} from 'chart.js';

Chart.register(ArcElement);

export default defineComponent({
  name: 'HalfGaugeChartKwt',
  props: {
    currentValue: {
      type: Number,
      required: true,
      default: 0,
    },
    setValue: {
      type: Number,
      required: true,
      default: 0,
    },
    maxValue: {
      type: Number,
      required: true,
      default: 90,
    },
  },
  setup(props) {
    const progressChart = ref(null);
    let chartInstance = null;

    const displayValue = ref(Math.min(props.currentValue, props.setValue));

    const createChart = () => {
      const ctx = progressChart.value.getContext('2d');
      const progressPercentage = (displayValue.value / props.setValue) * 100;

      chartInstance = new Chart(ctx, {
        type: 'doughnut',
        data: {
          datasets: [
            {
              data: [progressPercentage, 100 - progressPercentage],
              backgroundColor: [
                'rgb(23, 193, 232)',
                'rgba(211, 211, 211, 0.3)',
              ],
              borderColor: 'transparent',
              borderWidth: 5,
              cutout: '80%',
            },
          ],
        },
        options: {
          responsive: true,
          rotation: 225,
          circumference: 270,
          animation: {
            duration: 500, // Час анімації
          },
          plugins: {
            tooltip: {enabled: false},
          },
        },
      });
    };

    watch(
        () => [props.currentValue, props.setValue],
        ([newCurrentValue, newsetValue]) => {
          displayValue.value = Math.min(newCurrentValue, newsetValue);
          const progressPercentage = (displayValue.value / newsetValue) * 100;
          if (chartInstance) {
            chartInstance.data.datasets[0].data[0] = progressPercentage;
            chartInstance.data.datasets[0].data[1] = 100 - progressPercentage;
            chartInstance.update();
          }
        }
    );

    onMounted(() => {
      createChart();
    });

    return {
      progressChart,
      displayValue,
    };
  },
});
</script>

<style scoped>
.chart-power {
  position: relative;
  width: 200px;
  height: 200px;
}

.temperature-display {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  font-weight: bold;
  color: #333;
}
</style>
