<template>
  <div class="container-fluid py-4">
    <div class="row mt-4">
      <div class="col-12">
        <Table />
      </div>
    </div>
  </div>
</template>

<script>
import Table from "./components/Table.vue";

export default {
  name: "Reports",
  components: {
    Table,
  },
};
</script>
