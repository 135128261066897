<template>
  <div class="container my-6">
    <div class="row">
      <h1>Інформація з експлуатації та обслуговування пелетних котлів</h1>
      <div class="the_content my_custom_style"><h2><strong>1. Відео-інструкції</strong></h2>
        <p><strong>Перший запуск котла</strong><br>Як правильно підготувати та запустити котел уперше. Покрокове відео з
          детальними поясненнями.<br><a href="https://youtu.be/Nu5puLYNins">▶️ Переглянути відео</a></p>

        <p><strong>Обслуговування котла</strong><br>Регулярне технічне обслуговування та чищення пелетного котла для
          оптимальної роботи. Як і що робити?<br><a href="https://youtu.be/51Sc4j7-hH4?si=RODBY42IFrYhtVUR">▶️
            Переглянути відео</a></p>

        <p><strong>Встановлення колосників в пальнику BEETERM 100</strong><br>Покрокове відео з детальними
          поясненнями<br><a href="https://youtu.be/QjK0o4etk6g?si=Cqbutrt6TeQV0O4A">▶️ Переглянути відео</a></p>

        <p><strong>Встановлення колосників в пальнику BEETERM 75</strong><br>Покрокове відео з детальними
          поясненнями<br><a href="https://youtu.be/uuGEg3p2GB4">▶️ Переглянути відео</a></p>
        <p>&nbsp;</p>

        <h2><strong>2. Інструкції з обслуговування пелетних котлів</strong></h2>
        <p><img class="lazy loaded" decoding="async"
                src="https://beeterm.com/wp-content/themes/Beeterm/images/pdf-icon.svg"
                data-src="https://beeterm.com/wp-content/themes/Beeterm/images/pdf-icon.svg"
                data-pagespeed-url-hash="2295055879" onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                data-was-processed="true"> <a target="_blank"
            href="https://beeterm.com/wp-content/uploads/2018/06/Instruktsiya-z-obslugovuvannya-kotla-BEETERM-15-60-kVt.pdf">Інструкція
          з обслуговування котла BEETERM 15-60 кВт</a></p>
        <p><img class="lazy loaded" decoding="async"
                src="https://beeterm.com/wp-content/themes/Beeterm/images/pdf-icon.svg"
                data-src="https://beeterm.com/wp-content/themes/Beeterm/images/pdf-icon.svg"
                data-pagespeed-url-hash="2295055879" onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                data-was-processed="true"> <a target="_blank"
            href="https://beeterm.com/wp-content/uploads/2018/06/Instruktsiya-z-obslugovuvannya-kotla-BEETERM-70-100-kVt.pdf">Інструкція
          з обслуговування котла BEETERM 70-100 кВт</a></p>
        <p>&nbsp;</p>
        <h2>3. Інструкції з обслуговування пальників</h2>
        <p><img class="lazy loaded" decoding="async"
                src="https://beeterm.com/wp-content/themes/Beeterm/images/pdf-icon.svg"
                data-src="https://beeterm.com/wp-content/themes/Beeterm/images/pdf-icon.svg"
                data-pagespeed-url-hash="2295055879" onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                data-was-processed="true"> <a target="_blank"
            href="https://beeterm.com/wp-content/uploads/2018/06/Instruktsiya-z-obslugovuvannya-peletnogo-palnika-BEETERM-20-50.pdf">Інструкція
          з обслуговування пальника BEETERM 20-50</a></p>
        <p><img class="lazy loaded" decoding="async"
                src="https://beeterm.com/wp-content/themes/Beeterm/images/pdf-icon.svg"
                data-src="https://beeterm.com/wp-content/themes/Beeterm/images/pdf-icon.svg"
                data-pagespeed-url-hash="2295055879" onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                data-was-processed="true"> <a target="_blank"
            href="https://beeterm.com/wp-content/uploads/2018/06/Instruktsiya-z-obslugovuvannya-peletnogo-palnika-BEETERM-75-150.pdf">Інструкція
          з обслуговування пальника BEETERM 75-150</a></p>
        <p>&nbsp;</p>
        <h2><strong>4. Технічна підтримка</strong></h2>
        <p><strong>Телефон: +38 050 341 93 68</strong></p>
        <p><strong>Email: office@beeterm.com</strong></p>
      </div>
      <accordion
          title="Часті запитання"
          description=""
      >
        <accordion-item
            accordion-id="headingTwo"
            collapse-id="collapseTwo"
            question="Чому котел не запускається?"
        >
          Причин того, що котел не запускається може бути декілька
          <br/>
          1. Перевірте правильність встановлення колосників. Через неправильну установку колосників (поплутані місцями передня та задня частина колосника) повітря розподіляється не правильно і пелети не можуть розпалитися.
          <br/>
          2. Недостатньо стартової порції. Тобто кількості стартової порції пелети недостатньо для нормального розпалювання. У цьому випадку необхідно заново провести тарування пелети, що подається за 1 хвилину (як це зробити дивіться у відео Перший запуск котла, посилання вище), ввести кількість, що вийшла, в меню “Налаштування по паливу – Подача шнеком г/хв”
          <br/>
          3. Перевірте чи робочий ТЕН розпалювання. Для цього потрібно в Меню “Ручний режим” включити ТЕН розпалювання, і подивитися розжаритися він до червона.
        </accordion-item>

        <accordion-item
            accordion-id="headingThree"
            collapse-id="collapseThree"
            question="Як часто потрібно чистити котел та пальник?"
        >
          Періодичність чищення котла залежить від типу вибраних пелет та інтенсивності роботи обладнання
          <br/>
          – Якщо пеллети деревні (сосна, дуб тощо) – ми рекомендуємо проводити чистку один раз на тиждень.
          <br/>
          – Якщо пелети з лушпиння соняшника (не плутати з агропеллетою) – тоді ми рекомендуємо чистити з періодичністю один раз на 2-3 дні.
          <br/>
          – Якщо ж агропелети – то чищення може вимагати й кожні 2-3 години.
        </accordion-item>

        <accordion-item
            accordion-id="headingFour"
            collapse-id="collapseFour"
            question="Що робити, якщо котел не виходить на задану потужність?"
        >
          Швидше за все, недостатньо подається пелети. Це може бути пов’язано з тим, що згодом вага пелети яка подається, збивається і необхідно заново провести тарування, тобто перевірити відповідність скільки реально подається пелети з тим, що зазначено в налаштуваннях контролера
        </accordion-item>
      </accordion>
    </div>
  </div>

</template>

<script>
import image from "@/assets/img/team-3.jpg";
import Accordion from "./components/Accordion.vue";
import AccordionItem from "./components/AccordionItem.vue";
export default {
  name: "Faq",
  components: {
    AccordionItem, Accordion,
  },
  data() {
    return {
      image,
    };
  },
};
</script>
