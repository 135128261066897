<template>
  <div v-if="isVisible" class="modal" @click.self="close">
    <div class="modal-content">
      <span class="close" @click="close">&times;</span>
      <h2>{{ title }}</h2>

      <hr class="my-2 horizontal dark"/>
      <HalfGaugeChartKwt
          v-model:set-value="tempValue"
          :current-value="currentValue"
          :max-value="maxValue"
      />

      <div class="value_1">
        <h4 class="mb-0 font-weight-bolder">Налаштуйте потужність</h4>
        <NumberInputKwt v-model="tempValue" :setValue="setTempValue" :minValue="minValue" :maxValue="maxValue"/>
        <p class="d-none">Передане значення: {{ tempValue }}</p>
      </div>

      <hr class="my-2 horizontal dark"/>

      <div class="value_2">


          <div class="form-check form-switch ps-0 d-inline-flex">
            <span class="modul-mr-10">Модуляція</span>
            <input id="modulation" v-model="localmodulation" class="mt-1 form-check-input ms-auto" type="checkbox">
            <p class="d-none">Передане значення: {{ localmodulation }}</p>
          </div>
      </div>
      <hr class="my-4 horizontal dark"/>

      <div class="btn-footer">
        <button class="mb-0 btn bg-gradient-danger" @click="close">Скасувати</button>
        <button class="mb-0 btn bg-gradient-info" @click="save">Зберегти</button>
      </div>

    </div>
  </div>
</template>

<script>
import NumberInputKwt from "@/components/NumberInputKwt.vue";
import HalfGaugeChartKwt from "../Charts/HalfGaugeChartKwt.vue";

export default {
  name: "ModalPower",
  components: {
    HalfGaugeChartKwt,
    NumberInputKwt,
  },
  props: {
    title: {
      type: String,
      default: "Modal Title",
    },
    currentValue: {
      type: Number,
      required: true,
      default: 0,
    },
    minValue: {
      type: Number,
      required: true,
      default: 0,
    },
    maxValue: {
      type: Number,
      required: true,
      default: 100,
    },
    modulation: {
      type: Number,
      required: true,
      default: 0,
    },
    setTempValue: {
      type: Number,
      default: 100,
    },
  },
  emits: ['update:setTempValue', 'update:modulation'], // оголошення подій
  data() {
    return {
      isVisible: false,
      tempValue: this.setTempValue,
      localmodulation: this.modulation,
    };
  },
  watch: {
    tempValue(newValue) {
      this.$emit("update:setTempValue", newValue);
    },
    setTempValue(newValue) {
      this.tempValue = newValue; // синхронізація з пропсами
    },
    localmodulation(newValue) {
      this.$emit("update:modulation", newValue);
    },
  },
  methods: {
    open() {
      this.isVisible = true;
    },
    close() {
      this.isVisible = false;
    },
    save() {
      const modulationValue = this.localmodulation ? true : false;

      this.$emit("save", {
        setTemp: this.tempValue,
        modulation: modulationValue,
      });
      this.close();
    },
  },
};
</script>


<style scoped>
.modul-mr-10 {
  margin-right: 10px;
}
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 11111;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background: white;
  padding: 20px;
  max-width: 450px;
  border-radius: 5px;
}

.modal-content .btn-footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 21px;
}

.close {
  cursor: pointer;
  float: right;
  font-size: 2em;
}
</style>

