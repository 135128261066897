<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <h5 class="mb-0">Підключені пристрої</h5>
              <router-link to="/items/new" class="mb-0 btn bg-gradient-info btn-sm ms-auto mt-4 mt-lg-0"> + додати
              </router-link>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div v-if="itemsList?.length > 0" class="device-list">
              <div v-for="item in itemsList" :key="item.id"
                   class="device-item d-grid mb-3 p-3 border rounded">
                <div class="device-info">
                  <h6 class="device-name mb-1">{{ item.name }}</h6>
                  <p class="device-owner text-muted">Власник: {{ item.owner }}</p>
                  <p class="device-category text-muted">Тип: {{ item.category.name }}</p>
                </div>
                <div class="device-status text-muted">
                  <p>Статус: {{ item.status }}</p>
                  <p>Синхронізація: {{ item.sync_status }}</p>
                </div>
                <div class="device-created text-muted">
                  <p>Створений: {{ item.created_at }}</p>
                </div>
                <div class="device-actions mt-2 mt-md-0">
                  <button @click="handleEdit(item.id)" class="btn btn-edit-device bg-gradient-info  text-center">Редагувати</button>
                  <button @click="handleDelete(item.id)" class="btn btn-delete-device bg-danger  text-center">Видалити</button>
                </div>
              </div>
            </div>
            <div v-else class="text-center my-5">
              <p>Пристроїв не знайдено</p>
              <router-link to="/items/new" class="btn btn-add-device-big bg-gradient-info btn-sm text-center">
                <i class="fa fa-solid fa-plus"></i> Додати пристрій
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "/src/store";
import showSwal from "/src/mixins/showSwal.js";

export default {
  name: "Items",
  data() {
    return {
      pagination: {
        currentPage: 1,
        perPage: 15,
        total: 0,
      },
      currentQuery: "",
      currentSort: "created_at",
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters["profile/profile"]?.id;
    },
    itemsList() {
      return this.$store.getters["items/items"]?.data || [];
    },
    metaPage() {
      return this.$store.getters["items/items"]?.meta;
    },
  },
  watch: {
    metaPage: "updatePagination",
  },
  mounted() {
    this.loadData();
  },
  methods: {
    actionEditButton(userId, text) {
      var actionEdit = `
        <a id="${userId}" class="actionButton cursor-pointer me-3" data-bs-toggle="tooltip" title="${text}">
          <i class="fas fa-user-edit text-secondary"></i>
        </a>`;
      return actionEdit;
    },

    handleDelete(itemId) {
      // Підтвердження видалення через Swal
      showSwal.methods.showSwalConfirmationDelete().then(async (result) => {
        if (result.isConfirmed) {
          try {
            // Виконання видалення через Vuex
            await store.dispatch("items/deleteItem", itemId);
            // Оновлення списку після видалення
            this.loadData();
            showSwal.methods.showSwal({
              type: "success",
              message: "Пристрій видалено!",
            });
          } catch (error) {
            showSwal.methods.showSwal({
              type: "error",
              message: "Не вдалося видалити пристрій.",
            });
          }
        }
      });
    },

    async loadData() {
      await store.dispatch("items/getItems", {
        filter: this.currentQuery ? { name: this.currentQuery } : {},
        page: {
          number: this.pagination.currentPage,
          size: this.pagination.perPage,
        },
        sort: this.currentSort,
        user_id: this.currentUser,
      });
    },

    updatePagination() {
      const meta = this.metaPage;
      this.pagination.total = meta?.total || 0;
    },

    handleEdit(id) {
      this.$router.push(`/items/edit/${id}`);
    },
  },
};
</script>

<style scoped>
.device-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  padding: 1.5em;
}

.device-item {
  background: #fbfbfb;
}

.device-actions {
  display: flex;
  justify-content: space-around;
  gap: 15px;
}
.device-actions button {
  flex: 1;
  color: #ffffff;
  gap: 10px;
}
.btn-add-device-big {
  padding: 2rem;
}
</style>
