<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Змінити данні пристрою</h5>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <router-link to="/items" class="mb-0 btn bg-gradient-info btn-sm"
                  >&nbsp; Назад
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <label class="form-label mt-2 row mt-4">Найменування пристрою</label>
                <soft-model-input
                    id="name"
                    v-model="item.name"
                    type="text"
                    placeholder="Пристрій №1 (дім)"
                />
                <validation-error :errors="apiValidationErrors.name"/>
              </div>
              <div class="col-6">
                <label class="form-label mt-2 row mt-4">Власник</label>
                <soft-model-input
                    id="owner"
                    v-model="item.owner"
                    type="text"
                    placeholder="Власник(ФІО)"
                />
                <validation-error :errors="apiValidationErrors.owner"/>

              </div>
            </div>


            <label class="form-label mt-2 row mt-4">Адреса</label>
            <soft-model-input
                id="description"
                v-model="item.description"
                placeholder="Description"
            />
            <validation-error :errors="apiValidationErrors.description"/>


            <div class="row">
              <div class="col-md-2 col-sm-12">
                <label class="form-label mt-2 row mt-4">Тип пристрою</label>
                <select
                    id="choices-category"
                    v-model="getCategory"
                    class="form-control"
                    name="choices-category"
                ></select>
                <validation-error :errors="apiValidationErrors.category"/>

              </div>
            </div>
            <label class="form-label mt-2 row mt-4">Синхронізація з контроллером </label>
            <soft-model-switch
                id="homepage"
                v-model="item.is_on_homepage"
                name="homepage"
            />
            <span class="text-green">з'єднання встановлено</span>

            <div class="d-none">
              <label class="form-label mt-2 row mt-4 ">Статус</label>
              <div class="form-check">
                <input
                    id="published"
                    v-model="item.status"
                    value="published"
                    type="radio"
                    class="form-check-input"
                />
                <label for="published">Підкючений</label>
              </div>

              <div class="form-check">
                <input
                    id="archive"
                    v-model="item.status"
                    value="archive"
                    type="radio"
                    class="form-check-input"
                />
                <label for="archive">Немає з'єднання</label>
              </div>
            </div>


            <validation-error :errors="apiValidationErrors.status"/>


            <label class="form-label mt-2 row mt-4">Дата додавання пристрою</label>
            <Datepicker
                v-model="getDateCreate"
                disabled
                :enable-time-picker="false"
                :format="format"
                auto-apply
                placeholder="Дата додавання пристрою"
            ></Datepicker>
            <validation-error :errors="apiValidationErrors.created_at"/>

            <label class="form-label mt-2 row mt-4">Останнє оновлення</label>
            <Datepicker
                v-model="getDate"
                disabled
                :enable-time-picker="false"
                :format="format"
                auto-apply
                placeholder="Дата змін в пристрою"
            ></Datepicker>
            <validation-error :errors="apiValidationErrors.updated_at"/>


            <soft-button
                color="dark"
                variant="gradient"
                class="float-end mt-4 mb-0"
                size="sm"
                :is-disabled="loading ? true : false"
                @click="editItem"
            ><span v-if="loading" class="spinner-border spinner-border-sm"></span>
              <span v-else>Оновити данні</span></soft-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftModelInput from "/src/components/SoftModelInput.vue";
import SoftButton from "/src/components/SoftButton.vue";
import showSwal from "/src/mixins/showSwal.js";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "/src/mixins/form-mixin.js";
import SoftModelSwitch from "/src/components/SoftModelSwitch.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import Choices from "choices.js";

export default {
  name: "EditItem",
  components: {
    SoftModelInput,
    SoftButton,
    ValidationError,
    SoftModelSwitch,
    Datepicker,
  },
  mixins: [formMixin],
  data() {
    return {
      file: null,
      item: {},
      loading: false,
      getDate: null,
      getDateCreate: null,
      getCategory: null,
      getTags: [],
    };
  },

  computed: {
    categories() {
      const categs = this.$store.getters["categories/categories"]?.data;

      var response = [];
      if (categs) {
        categs.forEach((element) => {
          response.push({
            value: element.name,
            label: element.name,
            id: element.id,
          });
        });
      }
      return response;
    },
    // Отримуємо ID користувача зі стану
    currentUser() {
      return this.$store.getters["profile/profile"]?.id;
    },
    tags() {
      const tags = this.$store.getters["tags/tags"]?.data;
      var response = [];
      if (tags) {
        tags.forEach((element) => {
          response.push({
            value: element.name,
            label: element.name,
            id: element.id,
          });
        });
      }
      return response;
    },
  },

  async mounted() {
    // Отримуємо елемент за його ID
    this.item = await this.$store.dispatch("items/getItem", this.$route.params.id);

    console.log(typeof this.item.histeresis_kotla); // Має бути "number"

    // Перевірка прав доступу
    // if (this.item.user_id !== this.currentUser) {
    //   // Якщо користувач не має доступу до цього елемента, перенаправляємо або показуємо повідомлення
    //   showSwal.methods.showSwal({
    //     type: "error",
    //     message: "У вас немає прав на редагування цього елемента!",
    //   });
    //   this.$router.push("/items/list"); // Перенаправлення на список елементів
    //   return; // Завершуємо виконання методу, щоб уникнути подальших операцій
    // }

    // Якщо прав доступу достатньо, продовжуємо завантаження даних
    this.getDate = this.item.updated_at;
    this.getDateCreate = this.item.created_at;

    // Завантажуємо категорії і теги
    await this.$store.dispatch("categories/getCategories");

    // Ініціалізація Choices для категорії
    if (document.getElementById("choices-category")) {
      var element = document.getElementById("choices-category");
      new Choices(element, {
        searchEnabled: false,
        choices: [
          {
            value: "",
            label: "",
            selected: true,
            disabled: true,
          },
        ].concat(this.categories),
      }).setChoiceByValue((this.getCategory = this.item.category.name));
    }

  },
  methods: {
    format(date) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },

    async editItem() {

      this.resetApiValidation();
      this.loading = true;

      // Перевірка, чи є ID елемента
      if (!this.item.id) {
        console.error("ID елемента відсутнє. Оновлення неможливе.");
        showSwal.methods.showSwal({
          type: "error",
          message: "ID елемента відсутнє. Оновлення неможливе.",
        });
        this.loading = false;
        return;
      }

      // Збереження категорій і тегів
      const categoryId = this.categories.find((obj) => obj.value === this.getCategory)?.id;
      if (categoryId) {
        this.item.category = {type: "categories", id: categoryId};
      }

      // const userId = this.currentUser;
      // if (userId) {
      //   console.log(this.item.user_id)
      //   // Только проверка значения, не отправлять user как отношение
      //   if (this.item.user_id !== userId) {
      //     console.error("User ID mismatch!");
      //     showSwal.methods.showSwal({
      //       type: "error",
      //       message: "User ID mismatch!",
      //     });
      //     return;
      //   }
      // }
      // Форматування дати зміни
      if (this.getDate && this.getDate != this.item.updated_at) {
        this.item.updated_at = this.format(this.getDate);
      }

      try {
        // Збереження зображення, якщо воно змінене
        if (this.file) {
          this.item.image = await this.$store.dispatch("items/uploadPic", {
            id: this.item.id,
            pic: this.file,
          });
        }

        // Виконання запиту на редагування
        await this.$store.dispatch("items/editItem", this.item);

        // Оновлення дати після успішного збереження
        this.item.updated_at = new Date().toISOString();

        // Повідомлення про успіх
        showSwal.methods.showSwal({
          type: "success",
          message: "Дані оновлено!",
        });
        this.$router.push("/items");
      } catch (error) {
        // Обробка помилок
        if (error.response?.data?.errors) {
          this.setApiValidation(error.response.data.errors);
        } else {
          showSwal.methods.showSwal({
            type: "error",
            message: "Упс, помилка 350!",
            width: 350,
          });
        }
      } finally {
        this.loading = false;
      }
    }

  },
};
</script>
<style>
img {
  object-fit: cover;
}

.choices {
  margin-bottom: 0px !important;
}
</style>
