<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-6">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Основна інформація</h5>
              </div>
            </div>
          </div>

          <div class="card-body">
            <label class="form-label mt-2 row">ФІО</label>

            <soft-model-input
                id="name"
                v-model="user.name"
                type="text"
                placeholder="Your Name"
            />
            <validation-error :errors="apiValidationErrors.name"/>

              <label class="form-label mt-4">Телефон для зв'язку (не використовується для розсилки)</label>
              <soft-input
                  id="phoneNumber"
                  type="phone"
                  placeholder="+38 099 111 22 33"
              />

            <label class="form-label row mt-4">Email</label>
            <input
            id="email"
            type="text"
            class="form-control"
            name="email"
            :value="user.email"
            disabled=""
            />

            <label class="form-label row mt-4">Тип користувача (не вірно виводить)</label>
            <input
                id="role"
                type="text"
                class="form-control"
                name="role"
                :value="user.type"
                disabled
            />
<!--            <select-->
<!--                id="choices-roles"-->
<!--                v-model="getRole"-->
<!--                class="form-control"-->
<!--                name="choices-roles"-->
<!--                disabled-->
<!--            ></select>-->
<!--            <validation-error :errors="apiValidationErrors.roles"/>-->


            <soft-button
                color="dark"
                variant="gradient"
                class="float-end mt-4 mb-0"
                size="sm"
                :is-disabled="loading ? true : false"
                @click="editUser"
            ><span v-if="loading" class="spinner-border spinner-border-sm"></span>
              <span v-else>Оновити данні</span></soft-button>

          </div>

        </div>

        <div id="notifications" class="card mt-4">
          <div class="card-header">
            <h5>Сповіщення</h5>
          </div>
          <div class="card-body pt-0">
            <div class="table-responsive">
              <table class="table mb-0">
                <thead>
                <tr>
                  <th class="ps-1" colspan="4">
                    <p class="mb-0">Подія</p>
                  </th>
                  <th class="text-center">
                    <p class="mb-0">Email</p>
                  </th>
                  <th class="text-center">
                    <p class="mb-0">SMS</p>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td class="ps-1" colspan="4">
                    <div class="my-auto">
                      <span class="text-dark d-block text-sm">Подія1</span>
                      <span class="text-xs font-weight-normal"
                      >text1</span
                      >
                    </div>
                  </td>
                  <td>
                    <div class="ms-auto">
                      <soft-switch
                          id="mentionsEmail"
                          name="mentionsEmail"
                          checked
                      />
                    </div>
                  </td>
                  <td>
                    В розробці
                  </td>
                </tr>
                <tr>
                  <td class="ps-1" colspan="4">
                    <div class="my-auto">
                      <p class="text-sm mb-0">Log in from a new device</p>
                    </div>
                  </td>
                  <td>
                    <div class="ms-auto">
                      <soft-switch id="deviceEmail" name="deviceEmail" checked />
                    </div>
                  </td>

                  <td>
                    В розробці
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Змінити пароль</h5>
              </div>
            </div>
          </div>
          <div class="card-body">
            <label class="form-label mt-2 row">Поточний пароль(*додати перевірку)</label>
            <soft-model-input
                id="password"
                v-model="user.password"
                type="password"
                placeholder="Поточний пароль"
            />
            <validation-error :errors="apiValidationErrors.password"/>

            <label class="form-label row mt-4">Новий пароль</label>
            <soft-model-input
                id="password"
                v-model="user.password"
                type="password"
                placeholder="Новий пароль
"
            />
            <validation-error :errors="apiValidationErrors.password"/>

            <label class="form-label row mt-4">Підтведження нового паролю
            </label>
            <soft-model-input
                id="password_confirmation"
                v-model="user.password_confirmation"
                type="password"
                placeholder="Підтведження нового паролю"
            />
            <soft-button
                color="dark"
                variant="gradient"
                class="float-end mt-4 mb-0"
                size="sm"
                :is-disabled="loading ? true : false"
                @click="editUser"
            ><span v-if="loading" class="spinner-border spinner-border-sm"></span>
              <span v-else>Оновити пароль</span></soft-button>
          </div>

        </div>

        <div class="card mt-4">
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Змінити email</h5>
              </div>
            </div>
          </div>
          <div class="card-body">
            <label class="form-label mt-2 row">Поточний пароль(*додати перевірку)</label>
            <soft-model-input
                id="password"
                v-model="user.password"
                type="password"
                placeholder="Поточний пароль"
            />
            <validation-error :errors="apiValidationErrors.password"/>

            <label class="form-label row mt-4">Email</label>
            <soft-model-input
                id="email"
                v-model="user.email"
                type="text"
                placeholder="Email"
            />
            <validation-error :errors="apiValidationErrors.email"/>


            <soft-button
                color="dark"
                variant="gradient"
                class="float-end mt-4 mb-0"
                size="sm"
                :is-disabled="loading ? true : false"
                @click="editUser"
            ><span v-if="loading" class="spinner-border spinner-border-sm"></span>
              <span v-else>Оновити еmail</span></soft-button>
          </div>

        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">

        <div class="card mt-4">
          <div class="card-header">
            <h5>Видалення профілю</h5>
            <p class="text-sm mb-0">
              Після видалення облікового запису поновити не можливо.
            </p>
          </div>
          <div class="card-body d-sm-flex pt-0 justify-content-between">
            <div class="d-flex align-items-center mb-sm-0 mb-4">
              <div>
                <soft-switch id="deleteAccount" name="deleteAccount" />
              </div>
              <div class="ms-2">
          <span class="text-dark font-weight-bold d-block text-sm"
          >Підтвержую</span
          >
                <span class="text-xs d-block">Я хочу видалити свій акаунт.</span>
              </div>
            </div>

            <soft-button
                color="danger"
                variant="gradient"
                class="mb-0 ms-2"
                type="button"
                name="button"
            >Видалити</soft-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftModelInput from "/src/components/SoftModelInput.vue";
import SoftButton from "/src/components/SoftButton.vue";
import showSwal from "/src/mixins/showSwal.js";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "/src/mixins/form-mixin.js";
//import Choices from "choices.js";
import SoftInput from "@/components/SoftInput.vue";
import SoftSwitch from "@/components/SoftSwitch.vue";

export default {
  name: "EditUser",
  components: {
    SoftSwitch,
    SoftInput,
    SoftModelInput,
    SoftButton,
    ValidationError,
  },
  mixins: [formMixin],
  data() {
    return {
      file: null,
      user: {},
      loading: false,
      getRole: null,
      options: [],
    };
  },
  computed: {
    roles() {
      const roles = this.$store.getters["roles/roles"]?.data;
      var response = [];
      if (roles) {
        roles.forEach((element) => {
          response.push({
            value: element.name,
            label: element.name,
            id: element.id,
          });
        });
      }
      return response;
    },
    preview() {
      if (this.file) return URL.createObjectURL(this.file);
      else if (this.user.profile_image) return this.user.profile_image;
      else return require("/src/assets/img/placeholder.jpg");
    },
  },
  async mounted() {
    this.user = await this.$store.dispatch("users/getUser", this.$route.params.id);
    await this.$store.dispatch("roles/getRoles");

    // if (document.getElementById("choices-roles")) {
    //   var element = document.getElementById("choices-roles");
    //   new Choices(element, {
    //     searchEnabled: false,
    //     choices: [
    //       {
    //         value: "",
    //         label: "",
    //         selected: true,
    //         disabled: true,
    //       },
    //     ].concat(this.roles),
    //   }).setChoiceByValue((this.getRole = this.user.roles[0].name));
    // }
  },
  methods: {
    async editUser() {
      this.resetApiValidation();
      this.loading = true;

      const id = this.roles.filter((obj) => {
        return obj.value === this.getRole;
      })[0]?.id;

      if (id)
        this.user.roles = [
          {
            type: "roles",
            id: id,
          },
        ];

      try {
        if (this.file) {
          this.user.profile_image = await this.$store.dispatch("profile/uploadPic", {
            id: this.user.id,
            pic: this.file,
          });
        }
        await this.$store.dispatch("users/editUser", this.user);
        showSwal.methods.showSwal({
          type: "success",
          message: "User edited successfully!",
        });
        this.$router.push("/users/list");
      } catch (error) {
        if (error.response.data.errors) this.setApiValidation(error.response.data.errors);
        else
          showSwal.methods.showSwal({
            type: "error",
            message: "Oops, something went wrong!",
            width: 350,
          });
        this.loading = false;
      }
    },
    addedImage(files) {
      this.file = files[0];
    },
  },
};
</script>
<style>
img {
  object-fit: cover;
}

.choices {
  margin-bottom: 0px !important;
}
</style>
