import itemsService from "../services/items.service";

const initialState = {
  items: null,
  hasItems: false // Додане поле для відстеження наявності елементів
};

export const items = {
  namespaced: true,
  state: initialState,
  actions: {
    async getUserByItemId(_, itemId) {
      return await itemsService.getUserByItemId(itemId);
    },
    async getItemsByUserId({ commit }, userId) {
      const response = await itemsService.getItemsByUserId(userId);
      commit("SET_ITEMS_BY_USER", response);
      commit("updateHasItemsStatus", Array.isArray(response.data) && response.data.length > 0, { root: true }); // Оновлюємо глобальний статус
      commit("UPDATE_HAS_ITEMS_STATUS");
    },
    async getItems({ commit }, params) {
      const response = await itemsService.getItems(params);
      commit("SET_ITEMS", response);
      commit("updateHasItemsStatus", Array.isArray(response.data) && response.data.length > 0, { root: true }); // Оновлюємо глобальний статус
      commit("UPDATE_HAS_ITEMS_STATUS");
    },

    async getItem(_, id) {
      return await itemsService.getItem(id);
    },

    async deleteItem(_, id) {
      await itemsService.deleteItem(id);
    },

    async addItem(_, item) {
      return await itemsService.addItem(item);
    },

    async editItem(_, item) {
      return await itemsService.editItem(item);
    },
    async uploadPic(_, object) {
      return await itemsService.uploadItemPic(object);
    },

  },

  mutations: {
    SET_ITEMS(state, items) {
      state.items = items;
      state.hasItems = Array.isArray(items) && items.length > 0; // Оновлюємо `hasItems`
    },
    SET_ITEMS_BY_USER(state, itemsByUser) {
      state.itemsByUser = itemsByUser; // Додайте це поле в `initialState`, якщо потрібно
    },
    UPDATE_HAS_ITEMS_STATUS(state) {
      state.hasItems = Array.isArray(state.items) && state.items.length > 0;
    }
  },

  getters: {
    items(state) {
      return state.items;
    },
    hasItems(state) {
      // Геттер, який динамічно перевіряє наявність елементів
      return state.hasItems;
    }
  },
};
