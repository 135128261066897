<template>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div
              class="px-2 px-md-5 col-xl-5 col-lg-6 col-md-8 col-12 d-flex flex-column  order-2 order-md-1"
            >
              <div class="mt-2 mt-md-5 card card-plain white-bg">
                <div class="pb-0 text-left card-header">
                  <h3 class="text-info text-gradient">Скидання паролю</h3>
                  <p class="mb-0">
                    Ви отримаєте електронний лист максимум через 60 секунд
                  </p>
                </div>
                <div class="pb-3 card-body">
                  <Form
                    role="form"
                    class="text-start"
                    :validation-schema="schema"
                    @submit="handleSendEmail"
                  >
                    <label for="email">Email</label>

                    <soft-field
                      id="email"
                      v-model="email"
                      type="email"
                      placeholder="Email"
                      name="email"
                    />

                    <div class="text-center">
                      <soft-button
                        class="my-4 mb-2"
                        variant="gradient"
                        color="info"
                        full-width
                        :is-disabled="loading ? true : false"
                      >
                        <span
                          v-if="loading"
                          class="spinner-border spinner-border-sm"
                        ></span>
                        <span v-else>Надіслати</span>
                      </soft-button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
            <div class="mx-auto  mt-5 mt-md-8 col-xl-4 col-lg-5 col-md-6 d-flex flex-column  order-1 order-md-2">
              <div>
                <div>
                  <div class="logo text-center">
                    <Logo />
                  </div>
                  <p class="text-center pt-4">СИСТЕМА ВІДДАЛЕНОГО КЕРУВАННЯ
                    КОТЛАМИ ТА ПАЛЬНИКАМИ тмBEETERM</p>
                </div>
                <div>
                  <p class="text-center pt-md-12 pt-0">ver. 1.0.0</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import SoftField from "@/components/SoftField.vue";
import SoftButton from "@/components/SoftButton.vue";
import showSwal from "/src/mixins/showSwal.js";
const body = document.getElementsByTagName("body")[0];
import { Form } from "vee-validate";
import * as yup from "yup";

import { mapMutations } from "vuex";
import Logo from "@/components/Icon/Logo.vue";
export default {
  name: "SendEmail",
  components: {
    Logo,
    SoftField,
    SoftButton,
    Form,
  },

  data() {
    const schema = yup.object().shape({
      email: yup
        .string()
        .required("Email обов'язковий!")
        .email("Необіхдно вписати існуючий email!"),
    });
    return {
      loading: false,
      email: "",
      schema,
    };
  },

  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    async handleSendEmail() {
      this.loading = true;
      if ((process.env.VUE_APP_IS_DEMO ?? 1) == 1) {
        showSwal.methods.showSwal({
          type: "error",
          message: "Скидання пароля вимкнено в демонстраційній версії",
          width: 450,
        });
      } else {
        try {
          await this.$store.dispatch("reset/sendEmail", this.email);
          showSwal.methods.showSwal({
            type: "success",
            message: "Лист відправлено! Будь ласка, перевірте свою поштову скриньку.",
            width: 450,
          });
        } catch (error) {
          showSwal.methods.showSwal({
            type: "error",
            message: "Ця електронна адреса не зареєстрована!",
            width: 350,
          });
        }
      }

      this.loading = false;
    },
  },
};
</script>
