<template>
  <svg width="60" height="54" viewBox="0 0 60 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="60" height="54" fill="none"/>
    <path d="M28.9915 7.13962C28.4506 8.21466 27.8692 9.26178 27.3825 10.3647C26.125 13.1012 26.4495 15.7958 27.9639 18.281C29.2484 20.3892 30.7357 22.3857 32.196 24.3822C33.6158 26.3089 34.9003 28.2914 35.7386 30.5532C36.9555 33.8482 37.28 37.185 35.8062 40.5079C34.8733 42.6021 33.4265 44.2496 31.6417 45.5899C30.9386 46.1204 30.1814 46.5951 29.3566 47C29.6135 46.3857 29.8704 45.7714 30.1408 45.171C31.1684 42.8394 31.831 40.4241 31.6687 37.8412C31.5605 36.1239 31.0603 34.4904 30.1814 33.0384C28.7346 30.5951 27.1661 28.2216 25.6247 25.8482C24.0021 23.363 22.6365 20.7661 22.2444 17.7504C21.7441 13.8133 23.272 10.8255 26.3413 8.60558C27.1661 8.00524 28.072 7.53054 28.9374 7C28.9509 7.05585 28.978 7.09773 28.9915 7.13962Z" stroke="url(#paint0_linear)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M39.3898 39.1958C39.809 38.037 40.1876 37.0597 40.5256 36.0545C41.3098 33.6531 41.08 31.3215 39.7954 29.1854C38.7002 27.3424 37.4698 25.6112 36.307 23.8241C35.374 22.386 34.5897 20.8782 34.2788 19.1609C33.6973 15.9218 34.8737 13.4087 37.7537 11.7194C38.0918 11.5239 38.4298 11.3424 38.9166 11.0771C38.5785 11.7054 38.3351 12.1941 38.0647 12.6688C36.6991 15.1679 37.0912 17.5414 38.6191 19.7752C39.5791 21.1714 40.6473 22.4977 41.6479 23.866C42.9324 25.6251 43.96 27.5239 44.3386 29.7159C44.893 32.941 43.8518 35.5658 41.4721 37.6461C40.8501 38.1766 40.1605 38.6234 39.3898 39.1958Z" stroke="url(#paint1_linear)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19.5539 19.3418C19.3511 19.9282 19.1347 20.5146 18.9454 21.101C18.4587 22.6228 18.6885 24.1167 19.4998 25.4011C20.4598 26.9229 21.555 28.347 22.6638 29.7432C24.0294 31.4744 25.341 33.2336 26.0171 35.3837C27.2205 39.1673 26.1928 42.6158 23.137 45.0311C22.7043 45.3662 22.2581 45.6873 21.6632 46.1341C21.9336 45.3662 22.1364 44.7798 22.3663 44.1934C23.6914 40.8008 23.0829 37.7013 21.0007 34.8392C19.9866 33.443 18.9454 32.0887 17.9719 30.6786C16.6333 28.7659 15.6192 26.6856 15.6868 24.2563C15.7544 21.8828 17.7015 20.6263 19.5539 19.3418Z" stroke="url(#paint2_linear)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <defs>
      <linearGradient id="paint0_linear" x1="29.4917" y1="50.4983" x2="29.4917" y2="2.93382" gradientUnits="userSpaceOnUse">
        <stop stop-color="#009DFF"/>
        <stop offset="1" stop-color="#00FFFF"/>
      </linearGradient>
      <linearGradient id="paint1_linear" x1="39.3256" y1="41.655" x2="39.3256" y2="8.21876" gradientUnits="userSpaceOnUse">
        <stop stop-color="#009DFF"/>
        <stop offset="1" stop-color="#00FFFF"/>
      </linearGradient>
      <linearGradient id="paint2_linear" x1="21.1127" y1="48.4773" x2="21.1127" y2="16.6182" gradientUnits="userSpaceOnUse">
        <stop stop-color="#009DFF"/>
        <stop offset="1" stop-color="#00FFFF"/>
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
</script>
