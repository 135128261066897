<template>
<!--  <div id="profile" class="card card-body mt-4">-->
<!--    <div class="row justify-content-le align-items-left">-->
<!--      <div class="col-sm-auto col-12 my-auto">-->
<!--        <div class="h-100">-->
<!--          <h5 class="mb-1 font-weight-bolder">Alec Thompson 55</h5>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
  <div id="basic-info" class="card mt-4">
    <div class="card-header">
      <h5>Основна інформація</h5>
    </div>
    <div class="card-body pt-0">
      <div class="row">
        <div class="col-6">
          <label class="form-label">Ім'я</label>
          <soft-input id="firstName" type="text" placeholder="Ім'я" />
        </div>
        <div class="col-6">
          <label class="form-label">Прізвище</label>
          <soft-input id="lastName" type="text" placeholder="Прізвище" />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 col-6">
          <label class="form-label mt-2">Стать</label>
          <select
            id="choices-gender"
            class="form-control"
            name="choices-gender"
          >
            <option value="Male">Чоловік</option>
            <option value="Female">Жінка</option>
          </select>
        </div>
        <div class="col-sm-8">
          <div class="row">
            <div class="col-sm-5 col-5">
              <label class="form-label mt-2">День Народження</label>
              <select
                id="choices-month"
                class="form-control"
                name="choices-month"
              ></select>
            </div>
            <div class="col-sm-4 col-3">
              <label class="form-label mt-2">&nbsp;</label>
              <select
                id="choices-day"
                class="form-control"
                name="choices-day"
              ></select>
            </div>
            <div class="col-sm-3 col-4">
              <label class="form-label mt-2">&nbsp;</label>
              <select
                id="choices-year"
                class="form-control"
                name="choices-year"
              ></select>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <label class="form-label mt-2">Email</label>
          <soft-input id="email" type="email" placeholder="example@email.com" />
        </div>
        <div class="col-6">
          <label class="form-label mt-2">Повторення Email</label>
          <soft-input
            id="confirmEmail"
            type="email"
            placeholder="example@email.com"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <label class="form-label mt-2">Місто</label>
          <soft-input id="location" type="text" placeholder="Київ" />
        </div>
        <div class="col-6">
          <label class="form-label mt-2">Телефон</label>
          <soft-input
            id="phoneNumber"
            type="phone"
            placeholder="+38 099 111 22 33"
          />
        </div>
      </div>
      <soft-button
          class="float-end mt-6 mb-0"
          color="dark"
          variant="gradient"
          size="sm"
      >Оновити профіль</soft-button>
    </div>
  </div>
  <div id="password" class="card mt-4">
    <div class="card-header">
      <h5>Змінити пароль</h5>
    </div>
    <div class="card-body pt-0">
      <label class="form-label">Поточний пароль</label>
      <soft-input
        id="currentPassword"
        type="password"
        placeholder="Поточний пароль"
      />
      <label class="form-label">Новий пароль</label>
      <soft-input id="newPassword" type="password" placeholder="Новий пароль" />
      <label class="form-label">Підтведження нового паролю</label>
      <soft-input
        id="confirmPassword"
        type="password"
        placeholder="Повторно новий пароль"
      />
      <h5 class="mt-5">Вимоги до паролю:</h5>
      <ul class="text-muted ps-4 mb-0 float-start">
        <li>
          <span class="text-sm">Один спеціальний символ</span>
        </li>
        <li>
          <span class="text-sm">Мінімум 6 символів</span>
        </li>
        <li>
          <span class="text-sm">Один номер (рекомендовано 2)</span>
        </li>
        <li>
          <span class="text-sm">Часто змінюйте його</span>
        </li>
      </ul>
      <soft-button
        class="float-end mt-6 mb-0"
        color="dark"
        variant="gradient"
        size="sm"
        >Оновити пароль</soft-button
      >
    </div>
  </div>
  <div id="notifications" class="card mt-4">
    <div class="card-header">
      <h5>Сповіщення</h5>
      <p class="text-sm">
        Виберіть спосіб отримання сповіщень.
      </p>
    </div>
    <div class="card-body pt-0">
      <div class="table-responsive">
        <table class="table mb-0">
          <thead>
            <tr>
              <th class="ps-1" colspan="4">
                <p class="mb-0">Подія</p>
              </th>
              <th class="text-center">
                <p class="mb-0">Email</p>
              </th>
              <th class="text-center">
                <p class="mb-0">SMS</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <span class="text-dark d-block text-sm">Подія1</span>
                  <span class="text-xs font-weight-normal"
                    >text1</span
                  >
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <soft-switch
                    id="mentionsEmail"
                    name="mentionsEmail"
                    checked
                  />
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <soft-switch id="mentionsSms" name="mentionsSms" />
                </div>
              </td>
            </tr>
            <tr>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <span class="text-dark d-block text-sm">Подія1</span>
                  <span class="text-xs font-weight-normal"
                  >text1</span
                  >
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <soft-switch
                    id="commentsEmail"
                    name="commentsEmail"
                    checked
                  />
                </div>
              </td>

              <td>
                <div class="ms-auto">
                  <soft-switch id="commentsSms" name="commentsSms" />
                </div>
              </td>
            </tr>
            <tr>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <span class="text-dark d-block text-sm">Подія1</span>
                  <span class="text-xs font-weight-normal"
                  >text1</span
                  >
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <soft-switch id="followsEmail" name="followsEmail" checked />
                </div>
              </td>

              <td>
                <div class="ms-auto">
                  <soft-switch id="followsEmail" name="followsSms" checked />
                </div>
              </td>
            </tr>
            <tr>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <p class="text-sm mb-0">Log in from a new device</p>
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <soft-switch id="deviceEmail" name="deviceEmail" checked />
                </div>
              </td>

              <td>
                <div class="ms-auto">
                  <soft-switch id="deviceSms" name="deviceSms" checked />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div id="sessions" class="card mt-4">
    <div class="card-header pb-3">
      <h5>Сесії</h5>
      <p class="text-sm">
        Це список пристроїв, які ввійшли у ваш обліковий запис.
      </p>
    </div>
    <div class="card-body pt-0">
      <div class="d-flex align-items-center">
        <div class="text-center w-5">
          <i class="fas fa-desktop text-lg opacity-6"></i>
        </div>
        <div class="my-auto ms-3">
          <div class="h-100">
            <p class="text-sm mb-1">Bucharest 68.133.163.201</p>
            <p class="mb-0 text-xs">Your current session</p>
          </div>
        </div>
        <soft-badge color="success" size="sm" class="my-auto ms-auto me-3"
          >Active</soft-badge
        >
        <p class="text-secondary text-sm my-auto me-3">EU</p>

      </div>
      <hr class="horizontal dark" />
      <div class="d-flex align-items-center">
        <div class="text-center w-5">
          <i class="fas fa-desktop text-lg opacity-6"></i>
        </div>
        <p class="my-auto ms-3">Chrome on macOS</p>
        <p class="text-secondary text-sm ms-auto my-auto me-3">US</p>

      </div>
    </div>
  </div>
  <div id="delete" class="card mt-4">
    <div class="card-header">
      <h5>Видалення профілю</h5>
      <p class="text-sm mb-0">
        Після видалення облікового запису поновити не можливо.
      </p>
    </div>
    <div class="card-body d-sm-flex pt-0">
      <div class="d-flex align-items-center mb-sm-0 mb-4">
        <div>
          <soft-switch id="deleteAccount" name="deleteAccount" />
        </div>
        <div class="ms-2">
          <span class="text-dark font-weight-bold d-block text-sm"
            >Підтвержую</span
          >
          <span class="text-xs d-block">Я хочу видалити свій акаунт.</span>
        </div>
      </div>
      <soft-button
        color="secondary"
        variant="outline"
        class="mb-0 ms-auto"
        type="button"
        name="button"
        >Деактивувати</soft-button
      >
      <soft-button
        color="danger"
        variant="gradient"
        class="mb-0 ms-2"
        type="button"
        name="button"
        >Видалити</soft-button
      >
    </div>
  </div>
</template>

<script>
import * as Choices from "choices.js";
import SoftButton from "@/components/SoftButton.vue";
import SoftSwitch from "@/components/SoftSwitch.vue";
import SoftBadge from "@/components/SoftBadge.vue";
import SoftInput from "@/components/SoftInput.vue";
import img from "../../../../assets/img/bruce-mars.jpg";

export default {
  name: "SideNavItem",
  components: {
    SoftButton,
    SoftSwitch,
    SoftBadge,
    SoftInput,
  },
  data() {
    return {
      img,
    };
  },
  mounted() {
    if (document.getElementById("choices-gender")) {
      var gender = document.getElementById("choices-gender");
      new Choices(gender);
    }

    if (document.getElementById("choices-language")) {
      var language = document.getElementById("choices-language");
      new Choices(language);
    }

    if (document.getElementById("choices-month")) {
      var month = document.getElementById("choices-month");
      setTimeout(function () {
        new Choices(month);
      }, 1);

      // eslint-disable-next-line no-unused-vars
      var d = new Date();
      var monthArray = new Array();
      monthArray[0] = "January";
      monthArray[1] = "February";
      monthArray[2] = "March";
      monthArray[3] = "April";
      monthArray[4] = "May";
      monthArray[5] = "June";
      monthArray[6] = "July";
      monthArray[7] = "August";
      monthArray[8] = "September";
      monthArray[9] = "October";
      monthArray[10] = "November";
      monthArray[11] = "December";
      for (let m = 0; m <= 11; m++) {
        var optn = document.createElement("OPTION");
        optn.text = monthArray[m];
        // server side month start from one\
        optn.value = m + 1;
        // if june selected
        if (m == 1) {
          optn.selected = true;
        }
        month.options.add(optn);
      }
    }

    if (document.getElementById("choices-day")) {
      var day = document.getElementById("choices-day");
      setTimeout(function () {
        new Choices(day);
      }, 1);

      for (let y = 1; y <= 31; y++) {
        var optn1 = document.createElement("OPTION");
        optn1.text = y;
        optn1.value = y;

        if (y == 1) {
          optn1.selected = true;
        }

        day.options.add(optn1);
      }
    }

    if (document.getElementById("choices-year")) {
      var year = document.getElementById("choices-year");
      setTimeout(function () {
        new Choices(year);
      }, 1);

      for (let y = 1900; y <= 2020; y++) {
        var optn2 = document.createElement("OPTION");
        optn2.text = y;
        optn2.value = y;

        if (y == 2020) {
          optn.selected = true;
        }

        year.options.add(optn2);
      }
    }

    if (document.getElementById("choices-skills")) {
      var skills = document.getElementById("choices-skills");
      new Choices(skills, {
        delimiter: ",",
        editItems: true,
        maxItemCount: 5,
        removeItemButton: true,
        addItems: true,
      });
    }
  },
};
</script>
