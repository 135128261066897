<template>
  <div class="loader mt-4">
      <span>Яскравість полум'я</span>
    <div
        v-for="segment in segments"
        :key="segment"
        :class="['loader__segment', { 'loader__segment--active': segment <= activeSegments }]"
    ></div>
    <span style=" width: 54px; text-align: center; ">{{progress}} %</span>
  </div>
</template>

<script>
export default {
  name: "SegmentedLoader",
  props: {
    progress: {
      type: Number,
      required: true,
      validator: value => value >= 0 && value <= 100
    }
  },
  computed: {
    segments() {
      return Array.from({ length: 18 }, (_, index) => index + 1);
    },
    activeSegments() {
      return Math.round((this.progress / 100) * 18); // Обчислення кількості активних сегментів
    }
  }
};
</script>

<style scoped>
.loader {
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  align-content: center;

}
.loader__segment {
  width: 5%; /* Ширина кожного сегмента, 5% від батьківського контейнера */
  height: 10px;
  background-color: #ddd; /* Колір неактивних сегментів */
  transition: background-color 0.3s;
}
.loader__segment--active {
  background-image: linear-gradient(310deg, #17c1e8 0%, #17c1e8 100%)
}
</style>
