<template>
  <div class="number-input">
    <button @click="decrease" class="decrease-btn"></button>
    <input
        type="number"
        v-model.number="currentValue"
        @input="validateValue"
        class="input-field custom-input"
    /> kWt
    <button @click="increase" class="increase-btn"></button>
    <p class="current-value d-none">Поточне значення: {{ currentValue }}</p>
  </div>
</template>

<script>
export default {
  name: 'NumberInput',
  props: {
    modelValue: {
      type: Number,
      required: true,
      default: 0,
    },
    minValue: {
      type: Number,
      default: 0,
    },
    maxValue: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      currentValue: this.modelValue,
    };
  },
  methods: {
    increase() {
      if (this.currentValue < this.maxValue) {
        this.currentValue++;
        this.emitValue();
      }
    },
    decrease() {
      if (this.currentValue > this.minValue) {
        this.currentValue--;
        this.emitValue();
      }
    },
    validateValue() {
      if (this.currentValue < this.minValue) {
        this.currentValue = this.minValue;
      } else if (this.currentValue > this.maxValue) {
        this.currentValue = this.maxValue;
      }
      this.emitValue();
    },
    emitValue() {
      this.$emit('update:modelValue', this.currentValue); // Використовуємо update:modelValue для передачі значення
    },
  },
  watch: {
    modelValue(newValue) {
      this.currentValue = newValue;
    },
  },
};
</script>
<style>
.number-input{
  padding: 15px 0;
  color: #000;
  font-size: 1.5em;
}
.decrease-btn {
  width: 18px;
  height: 24px;
  background-repeat: no-repeat;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' focusable='false' data-prefix='fad' data-icon='chevron-left' role='img' viewBox='0 0 320 512' class='svg-inline--fa fa-chevron-left fa-w-10 fa-9x'%3E%3Cg class='fa-group'%3E%3Cpath fill='%2300eeff' d='M285.59 410.4a23.93 23.93 0 0 1 0 33.84l-22.7 22.65a24 24 0 0 1-33.94 0l-154.31-154L131.42 256z' class='fa-secondary'/%3E%3Cpath fill='%2300aaff' d='M262.85 45.06l22.7 22.65a23.93 23.93 0 0 1 0 33.84L74.58 312.9l-40-40a23.94 23.94 0 0 1 0-33.84l194.33-194a24 24 0 0 1 33.94 0z' class='fa-primary'/%3E%3C/g%3E%3C/svg%3E");
  background-color: #fff;
  vertical-align: middle;
}
.increase-btn{
  width: 18px;
  height: 24px;
  background-repeat: no-repeat;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' focusable='false' data-prefix='fad' data-icon='chevron-right' role='img' viewBox='0 0 320 512' class='svg-inline--fa fa-chevron-right fa-w-10 fa-9x'%3E%3Cg class='fa-group'%3E%3Cpath fill='%2300aaff' d='M188.74 256l56.78 56.89L91.21 466.9a24 24 0 0 1-33.94 0l-22.7-22.65a23.93 23.93 0 0 1 0-33.84z' class='fa-secondary'/%3E%3Cpath fill='%2300eeff' d='M91.25 45.06l194.33 194a23.93 23.93 0 0 1 0 33.84l-40 40-211-211.34a23.92 23.92 0 0 1 0-33.84l22.7-22.65a24 24 0 0 1 33.97-.01z' class='fa-primary'/%3E%3C/g%3E%3C/svg%3E");
  background-color: #fff;
  vertical-align: middle;
}
.custom-input {
  width: 50px;
  text-align: center;
  margin: 0 auto;
  padding: 0;
  border: navajowhite;
  font-size: 1em;
  margin-right: -10px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
