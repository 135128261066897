<template>
  <div
    id="sidenav-collapse-main"
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-collapse
            nav-text="Головна"
            :class="getRoute() === 'dashboards' ? 'active' : ''"
            :collapse="false"
            url="#"
            :aria-controls="''"
            :to="{ name: 'Default' }"
            collapse-ref="/dashboards"
        >
          <template #icon>
            <Shop />
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-collapse
            nav-text="Підключені пристрої"
            :class="getRoute() === '/items' ? 'active' : ''"
            :collapse="false"
            url="#"
            :aria-controls="''"
            :to="{ name: 'Items' }"
            collapse-ref="/items"
        >
          <template #icon>
            <Office />
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-collapse
            nav-text="Сервісіні налаштування"
            :class="getRoute() === 'service' ? 'active' : ''"
            :collapse="false"
            url="#"
            :aria-controls="''"
            :to="{ name: 'Service' }"
            collapse-ref="/service"
        >
          <template #icon>
            <Settings />
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-collapse
            nav-text="Оновлення"
            :class="getRoute() === 'update' ? 'active' : ''"
            :collapse="false"
            url="#"
            :aria-controls="''"
            :to="{ name: 'Update' }"
            collapse-ref="/update"
        >
          <template #icon>
            <Spaceship />
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-collapse
            nav-text="Користувачі"
            :class="getRoute() === '/users/list' ? 'active' : ''"
            :collapse="false"
            url="#"
            :aria-controls="''"
            :to="{ name: 'Default' }"
            collapse-ref="/users/list"
        >
          <template #icon>
            <i class="fas fa-user-circle fa-7x m-0  top-0 text-dark-blue" aria-hidden="true"></i>
          </template>
        </sidenav-collapse>
      </li>

      <li class="mt-3 nav-item">
        <hr class="mt-0 horizontal dark" />
        <h6
          class="text-xs ps-4 ms-2 text-uppercase font-weight-bolder opacity-6"
          :class="isRTL ? 'me-4' : 'ms-2'"
        >
        </h6>
      </li>

      <li class="nav-item">
        <sidenav-collapse
          nav-text="Помилки"
          :collapse="false"
          url="#"
          :aria-controls="''"
          collapse-ref="/items/errors"
        >
          <template #icon>
            <i class="fas fa-exclamation-triangle fa-7x m-0 top-0 text-dark-blue"></i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
            nav-text="Справка"
            :class="getRoute() === 'faq' ? 'active' : ''"
            :collapse="false"
            url="#"
            :aria-controls="''"
            :to="{ name: 'Faq' }"
            collapse-ref="/pages/faq"
        >
          <template #icon>
            <i class="fas fa-question-circle  fa-7x m-0 top-0 text-dark-blue"></i>
          </template>
        </sidenav-collapse>
      </li>
    </ul>
  </div>
  <div class="pt-3 mx-3 mt-3 sidenav-footer">
    <sidenav-card
      :class="cardBg"
      text-primary="Потрібна допомога?"
      text-secondary=""
      route=""
      label="Написати"
      icon="ni ni-diamond"
    />
  </div>
</template>
<script>
import SidenavCollapse from "./SidenavCollapse.vue";
import SidenavCard from "./SidenavCard.vue";
import Settings from "../../components/Icon/Settings.vue";
import Shop from "../../components/Icon/Shop.vue";
import Office from "../../components/Icon/Office.vue";
import { mapState } from "vuex";
import Spaceship from "@/components/Icon/Spaceship.vue";
export default {
  name: "SidenavList",
  components: {
    Spaceship,
    SidenavCollapse,
    SidenavCard,
    Settings,
     Shop,
    Office,
  },
  props: {
    cardBg: {
      type: String,
      default: "",
    },
  },

  computed: {
    ...mapState(["isRTL"]),
    profile() {
      return this.$store.getters["profile/profile"];
    },
    requireAdmin() {
      if (this.$store.getters["auth/loggedIn"] && this.profile) {
        if (this.profile.roles[0].name == "admin") return true;
      }

      return false;
    },
    requireCreator() {
      if (this.$store.getters["auth/loggedIn"] && this.profile) {
        const aux = this.profile.roles[0].name;
        if (aux == "admin" || aux == "creator") return true;
      }
      return false;
    },
  },
  async created() {
    if (this.$store.getters["auth/loggedIn"]) {
      try {
        await this.$store.dispatch("profile/getProfile");
        this.profileChange = { ...this.profile };
      } catch (error) {
        try {
          await this.$store.dispatch("auth/logout");
        } finally {
          this.$router.push("/login");
        }
      }
    }
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
};
</script>
