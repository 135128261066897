<template>
  <div class="chart m-auto">
    <canvas :id="canvasId"></canvas>
    <div class="temperature-display">{{ currentValue }}°С</div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, watch, onBeforeUnmount } from 'vue';
import { Chart, ArcElement } from 'chart.js';

Chart.register(ArcElement);

export default defineComponent({
  name: 'HalfGaugeChart',
  props: {
    currentValue: {
      type: Number,
      required: true,
      default: 0,
    },
    setValue: {
      type: Number,
      required: true,
      default: 0,
    },
    maxValue: {
      type: Number,
      required: true,
      default: 90,
    },
  },
  setup(props) {
    const progressChart = ref(null);
    const canvasId = `chart-${Math.random().toString(36).substr(2, 9)}`; // Уникальный ID для каждого графика
    let chartInstance = null;

    const displayValue = ref(Math.min(props.currentValue, props.setValue));

    const createChart = () => {
      if (chartInstance) {
        chartInstance.destroy(); // Уничтожаем старый экземпляр графика
      }

      const canvas = document.getElementById(canvasId);
      const ctx = canvas.getContext('2d');

      const progressPercentage = (displayValue.value / props.setValue) * 100;

      chartInstance = new Chart(ctx, {
        type: 'doughnut',
        data: {
          datasets: [
            {
              data: [progressPercentage, 100 - progressPercentage],
              backgroundColor: ['rgb(23, 193, 232)', 'rgba(211, 211, 211, 0.3)'],
              borderColor: 'transparent',
              borderWidth: 5,
              cutout: '80%',
            },
          ],
        },
        options: {
          responsive: true,
          rotation: 225,
          circumference: 270,
          animation: {
            duration: 500,
          },
          plugins: {
            tooltip: { enabled: false },
          },
        },
      });
    };

    watch(
        () => [props.currentValue, props.setValue],
        ([newCurrentValue, newSetValue]) => {
          displayValue.value = Math.min(newCurrentValue, newSetValue);
          const progressPercentage = (displayValue.value / newSetValue) * 100;
          if (chartInstance) {
            chartInstance.data.datasets[0].data[0] = progressPercentage;
            chartInstance.data.datasets[0].data[1] = 100 - progressPercentage;
            chartInstance.update();
          }
        }
    );

    onMounted(() => {
      createChart();
    });

    onBeforeUnmount(() => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    });

    return {
      progressChart,
      canvasId,
      displayValue,
    };
  },
});
</script>

<style scoped>
.chart {
  position: relative;
  width: 200px;
  height: 200px;
}

.temperature-display {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  font-weight: bold;
  color: #333;
}
</style>
