<template>
  <div class="container-fluid mt-4">
    <div class="row align-items-center mb-2">
      <div class="col-12">
        <nav-pill />
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-12 mt-lg-0 mt-4">
        <side-nav-item />
      </div>
    </div>
  </div>
</template>

<script>
import NavPill from "./components/NavPill.vue";
import SideNavItem from "./components/SideNavItem.vue";

export default {
  name: "Settings",
  components: {
    NavPill,
    SideNavItem,
  },
};
</script>
